import img0 from './images/cj/2-1/imgs/1.png';
import img1 from './images/cj/2-1/imgs/2.png';
import img2 from './images/cj/2-1/imgs/3.png';
import img3 from './images/cj/2-1/imgs/4.png';
import img4 from './images/cj/2-1/imgs/5.png';
import img5 from './images/cj/2-1/imgs/6.png';
import img6 from './images/cj/2-1/imgs/7.png';
import img7 from './images/cj/2-1/imgs/8.png';
import img8 from './images/cj/2-1/imgs/9.png';
import img9 from './images/cj/2-1/imgs/10.png';
import img10 from './images/cj/2-2/imgs/1.png';
import img11 from './images/cj/2-2/imgs/2.png';
import img12 from './images/cj/2-2/imgs/3.png';
import img13 from './images/cj/2-2/imgs/4.png';
import img14 from './images/cj/2-2/imgs/5.png';
import img15 from './images/cj/2-2/imgs/6.png';
import img16 from './images/cj/2-2/imgs/7.png';
import img17 from './images/cj/2-2/imgs/8.png';
import img18 from './images/cj/2-2/imgs/9.png';
import img19 from './images/cj/2-2/imgs/10.png';
import img20 from './images/cj/2-3/imgs/1.png';
import img21 from './images/cj/2-3/imgs/2.png';
import img22 from './images/cj/2-3/imgs/3.png';
import img23 from './images/cj/2-3/imgs/4.png';
import img24 from './images/cj/2-3/imgs/5.png';
import img25 from './images/cj/2-3/imgs/6.png';
import img26 from './images/cj/2-3/imgs/7.png';
import img27 from './images/cj/2-3/imgs/8.png';
import img28 from './images/cj/2-3/imgs/9.png';
import img29 from './images/cj/2-3/imgs/10.png';
import img30 from './images/cj/2-4/imgs/1.png';
import img31 from './images/cj/2-4/imgs/2.png';
import img32 from './images/cj/2-4/imgs/3.png';
import img33 from './images/cj/2-4/imgs/4.png';
import img34 from './images/cj/2-4/imgs/5.png';
import img35 from './images/cj/2-4/imgs/6.png';
import img36 from './images/cj/2-4/imgs/7.png';
import img37 from './images/cj/2-4/imgs/8.png';
import img38 from './images/cj/2-4/imgs/9.png';
import img39 from './images/cj/2-4/imgs/10.png';
import video1 from './images/video1.png';
import video2 from './images/video2.png';
import video3 from './images/video3.png';
import video4 from './images/video4.png';
export const images = [
  [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9],
  [img10, img11, img12, img13, img14, img15, img16, img17, img18, img19],
  [img20, img21, img22, img23, img24, img25, img26, img27, img28, img29],
  [img30, img31, img32, img33, img34, img35, img36, img37, img38, img39],
];
export const videoImgs = [video1, video2, video3, video4];
