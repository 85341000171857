import React from "react";
import { useHistory } from "react-router-dom";
import { BASE_ROUTE_NAME } from "../../../../config";
import threeC from "./images/3C.png";
import makeUp from "./images/makeUp.png";
import consumption from "./images/consumption.png";

import useViewport from "../../../../hooks/useViewport";
import "./media.less";
import "./index.less";

const Solution = () => {
  const { isMobile } = useViewport();
  const history = useHistory();

  return (
    <div className={`${isMobile ? "solution-mobile" : "solution"}`}>
      <div className="solution-content">
        <div className="solution-text">
          {!isMobile && <h3>满足多种业务需求的行业解决方案</h3>}
          <h2>行业解决方案</h2>
          <p></p>
          <span>
            {isMobile && <span>满足多种业务需求的行业解决方案</span>}
            <span> 沉淀细分行业know-how</span>

            <span>从咨询的角度帮助客户解决问题</span>
          </span>
        </div>
        <div className="industry">
          <div
            className="solution-img"
            id="solutions"
            style={{ backgroundImage: `url(${consumption})` }}
            onClick={() =>
              isMobile && history.push(`${BASE_ROUTE_NAME}/solution/fb`)
            }
          >
            <div className="solution-img-mask">
              <div className="solution-img-content">
                <span>
                  基于衔远ProductGPT完成智能洞察分析，通过社媒数据进行舆情监测和竞品调研，
                  发现蓝海机会，并通过智能营销快速占领市场。
                </span>
                <div className="solution-split-line"></div>
                <div className="solution-img-title">食品饮料 </div>
              </div>
            </div>
            <p>
              食品饮料
              {isMobile && <span className="iconfont icon-right"></span>}
            </p>

            {/* <button></button> */}
            <div
              className="solution-hove-btn"
              onClick={() => history.push(`${BASE_ROUTE_NAME}/solution/fb`)}
            >
              <div className="solution-hove-btn-warp">
                <img
                  className="solution-hover-icon"
                  src={require("./images/arrow-icon.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="solution-img"
            id="solutions"
            style={{ backgroundImage: `url(${makeUp})` }}
            onClick={() =>
              isMobile && history.push(`${BASE_ROUTE_NAME}/solution/mxx`)
            }
          >
            <div className="solution-img-mask">
              <div className="solution-img-content">
                <span>
                  用AIGC的技术帮助客户以极高的效率和极低的成本
                  解决传统商品图拍摄长周期和高成本问题，生成高可用的产品营销图
                </span>
                <div className="solution-split-line"></div>
                <div className="solution-img-title">时尚潮流</div>
              </div>
            </div>
            <p>
              时尚潮流
              {isMobile && <span className="iconfont icon-right"></span>}
            </p>
            {/* <button></button> */}
            <div
              className="solution-hove-btn"
              onClick={() => history.push(`${BASE_ROUTE_NAME}/solution/mxx`)}
            >
              <div className="solution-hove-btn-warp">
                <img
                  className="solution-hover-icon"
                  src={require("./images/arrow-icon.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="solution-img"
            id="solutions"
            style={{ backgroundImage: `url(${threeC})` }}
            onClick={() =>
              isMobile && history.push(`${BASE_ROUTE_NAME}/solution/fmcg`)
            }
          >
            <div className="solution-img-mask">
              <div className="solution-img-content">
                <span>
                  基于衔远ProductGPT完成智能洞察分析，通过社媒数据进行舆情监测和竞品调研，
                  发现蓝海机会，并通过智能营销快速占领市场。
                </span>
                <div className="solution-split-line"></div>
                <div className="solution-img-title">快消零售</div>
              </div>
            </div>
            <p>
              快消零售
              {isMobile && <span className="iconfont icon-right"></span>}
            </p>
            {/* <button></button> */}
            <div
              className="solution-hove-btn"
              onClick={() => history.push(`${BASE_ROUTE_NAME}/solution/fmcg`)}
            >
              <div className="solution-hove-btn-warp">
                <img
                  className="solution-hover-icon"
                  src={require("./images/arrow-icon.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Solution;
