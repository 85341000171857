import { useEffect } from "react";

import { setHeader } from "../config/setHeader";

export const useSetHeader = ({
  title,
  description,
  keywords,
}: {
  title?: string;
  description?: string;
  keywords?: string;
}) => {
  useEffect(() => {
    if (!title || !description || !keywords) {
      setHeader({
        title: "衔远科技，全球领先的产品数智化技术服务者",
        description:
          "衔远科技是产品数智化全链路技术服务者，包括产品计划、市场洞察、产品概念、概念验证、产品定义，覆盖新品开发流程、老品升级流程与自有方案评估，助力打造爆品。",
        keywords:
          "衔远科技,周伯文,产品参谋,产品数智化,AI爆品设计,工业协同创新平台,产品创新",
      });
      return;
    }
    setHeader({
      title,
      description,
      keywords,
    });
  }, [title, description, keywords]);
};
