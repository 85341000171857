import { FC, memo } from "react";
// import Image from "../../../../components/Image";
import img from "./images/5D.png";
import "./index.less";
import "./media.less";
import useViewport from "../../../../hooks/useViewport";
const LModel: FC = memo(() => {
  const { isMobile } = useViewport();

  return (
    <div className={`${isMobile ? "model-box-mobile" : "model-box"}`}>
      <div>
        <img src={img} alt="" />
        {/* <Image src={img} alt="" /> */}
      </div>
    </div>
  );
});
export default LModel;
