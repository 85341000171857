import axios from "axios";
import toast from "react-hot-toast";
import {
  useState,
  // useRef
} from "react";
import tow from "../../images/erweima.jpeg";
import { REACT_APP_REQUEST_URL } from "../../../env";
import useViewport from "../../../hooks/useViewport";
import { getQueryString } from "../../../config/index";
import "./index.less";
import "./media.less";
import checkedIcon from "../../../images/checked.svg";
import noCheckIcon from "../../../images/no-check.svg";

const AlterWindow = ({ setAlterFlag, bgImg, setAltSubmit, submitDom }: any) => {
  const { isMobile } = useViewport();
  // let times = useRef<NodeJS.Timeout>();
  const validatePhone =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  const validateEmails = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  const [nameFlag, setNameFlag] = useState(true); //姓名校验
  const [phoneFlag, setPhoneFlag] = useState(true); //手机号校验
  // const [codeFlag, setCodeFlag] = useState(true); //验证码校验
  const [companyNameFlag, setCompanyNameFlag] = useState(true); //公司名称校验
  const [addressFlag, setAddressFlag] = useState(true); // 公司地址校验
  const [emailsFlag, setEmailsFlag] = useState(true); //邮箱校验
  // const [timers, setTimers] = useState(60); //时间
  // const [timeFlag, setTimeFlag] = useState(false); //是否开启倒计时
  const [formInfo, setFormInfo] = useState<any>({
    name: "",
    phone: "",
    companyName: "",
    verifyCode: "",
    email: "",
    address: "",
    description: "",
  });
  const alterFlag = (e: any) => {
    if (e.target.className === "alter-window") {
      setAlterFlag(false);
    }
  };

  // const countDown = (num: any) => {
  //   times.current = setTimeout(() => countDown(num), 1000);
  //   setTimers((num -= 1));
  //   if (num <= 0) {
  //     setTimers(60);
  //     setTimeFlag(false);
  //     clearTimeout(times.current);
  //   }
  // };

  const onSubmit = () => {
    if (
      phoneFlag &&
      // codeFlag &&
      companyNameFlag &&
      emailsFlag &&
      formInfo.phone.trim() !== "" &&
      formInfo.companyName.trim() !== "" &&
      // formInfo.verifyCode.trim() !== "" &&
      formInfo.email.trim() !== "" &&
      formInfo.address.trim() !== ""
    ) {
      let obj = {};
      const content = getQueryString("utm_content");
      const campaign = getQueryString("utm_medium");
      const source = getQueryString("utm_source");
      if (content) {
        obj = Object.assign({}, obj, { content: decodeURIComponent(content) });
      }
      if (campaign) {
        obj = Object.assign({}, obj, {
          campaign: decodeURIComponent(campaign),
        });
      }
      if (source) {
        obj = Object.assign({}, obj, {
          source: decodeURIComponent(source),
        });
      }
      axios
        .post(
          `${REACT_APP_REQUEST_URL}/customerinfo/withoutVerifyPhoneNumber`,
          Object.assign({}, formInfo, obj)
        )
        .then((res) => {
          if (res.data.code === 0) {
            toast.success("提交成功");
            setFormInfo({
              name: "",
              phone: "",
              companyName: "",
              verifyCode: "",
              email: "",
              description: "",
            });
            setAlterFlag(false);
            //提交成功的遮罩层开关
            setAltSubmit(true);
          } else if (res.data.code === 0) {
            toast.error(res.data.msg);
          } else {
            toast.error(res.data.msg);
          }
        });
    } else {
      let newObj = JSON.parse(JSON.stringify(formInfo));
      for (let key in newObj) {
        if (newObj[key].trim() === "" && key === "phone") {
          setPhoneFlag(false);
        } else if (newObj[key].trim() === "" && key === "companyName") {
          setCompanyNameFlag(false);
        } else if (newObj[key].trim() === "" && key === "address") {
          setAddressFlag(false);
        } else if (newObj[key].trim() === "" && key === "verifyCode") {
          // setCodeFlag(false);
        } else if (newObj[key].trim() === "" && key === "email") {
          setEmailsFlag(false);
        }
      }
    }
  };

  // const getCode = () => {
  //   if (validatePhone.test(formInfo.phone)) {
  //     axios({
  //       method: "get",
  //       url: `${REACT_APP_REQUEST_URL}/frontis-privilege-manage/noauth/code/portalphonesend?phone=${formInfo.phone}`,
  //     }).then((res) => {
  //       if (res.data.code === 0) {
  //         toast.success("验证码获取成功");
  //         countDown(timers);
  //         setTimeFlag(true);
  //       } else {
  //         toast.error(res.data.msg);
  //       }
  //     });
  //   } else {
  //     setPhoneFlag(false);
  //     toast('请输入正确的手机号')
  //   }
  // };
  const [agree, setAgree] = useState(true);

  const validForm = () => {
    let arr = [];

    for (var i in formInfo) {
      if (!formInfo[i] && i !== "verifyCode") {
        arr.push(i);
      }
    }
    return arr.length || !agree ? false : true;
  };

  return (
    <div
      className={`${isMobile ? "alter-window-mobile" : "alter-window"}`}
      style={
        bgImg
          ? { backgroundImage: `url(${bgImg})` }
          : { backgroundColor: "rgba(0, 0, 0, 0.8)" }
      }
      onClick={alterFlag}
    >
      <dl className="alter-content">
        <dt>
          <h1>国内首个产品创新</h1>
          <b>AI智能服务平台</b>
          <p>AI赋能创新模式，持续打造爆款产品。</p>
          <img src={tow} alt="" />
          <div>官方客服微信</div>
        </dt>

        <dd>
          {submitDom}
          <h1>立即免费预约产品试用</h1>
          <p>请完善信息，让我们为您更好的服务</p>
          <ul className="alter-form">
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    setNameFlag(e.target.value.trim() !== "");
                    return { ...formInfo, name: e.target.value };
                  })
                }
                placeholder="请填写真实姓名"
                value={formInfo.name}
              />
            </li>
            <li
              style={{ visibility: nameFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              正确填写姓名，我们的销售才能更好的为您提供服务
            </li>
            {/* 留资不需要验证码 */}
            {/* <div className="phone-box">
              <li>
                <input
                  type="text"
                  onChange={(e) =>
                    setFormInfo(() => {
                      setPhoneFlag(
                        e.target.value.trim() !== "" &&
                          validatePhone.test(e.target.value)
                      );
                      return { ...formInfo, phone: e.target.value };
                    })
                  }
                  placeholder="请输入您的手机号"
                  value={formInfo.phone}
                />
              </li>
              <li className="code-box">
                <input
                  type="text"
                  onChange={(e) =>
                    setFormInfo(() => {
                      setCodeFlag(e.target.value.trim() !== "");
                      return { ...formInfo, verifyCode: e.target.value };
                    })
                  }
                  placeholder="输入验证码"
                  value={formInfo.verifyCode}
                />
                <span
                  onClick={getCode}
                  style={{ color: timeFlag ? "#ccc" : "#2352D8", cursor: 'pointer' }}
                >
                  {timeFlag ? `${timers}后重新获取` : "获取验证码"}
                </span>
              </li>
              <li style={{ visibility: phoneFlag ? "hidden" : "inherit" }} className='i-text'>
                正确填写手机号，我们的销售才能更好的为您提供服务
              </li>
            </div> */}
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    return { ...formInfo, phone: e.target.value };
                  })
                }
                onBlur={(e) => {
                  setPhoneFlag(
                    e.target.value.trim() !== "" &&
                      validatePhone.test(e.target.value)
                  );
                }}
                placeholder="请输入您的手机号"
                value={formInfo.phone}
              />
            </li>
            <li
              style={{ visibility: phoneFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              正确填写手机号，我们的销售才能更好的为您提供服务
            </li>
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    setCompanyNameFlag(e.target.value.trim() !== "");
                    return { ...formInfo, companyName: e.target.value };
                  })
                }
                placeholder="请填写真实的公司名称"
                value={formInfo.companyName}
              />
            </li>
            <li
              style={{ visibility: companyNameFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              正确填写公司名称，我们的销售才能更好的为您提供服务
            </li>
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    setAddressFlag(e.target.value.trim() !== "");
                    return { ...formInfo, address: e.target.value };
                  })
                }
                placeholder="请输入您的公司地址"
                value={formInfo.address}
              />
            </li>
            <li
              style={{ visibility: addressFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              正确填写公司地址，我们的销售才能更好的为您提供服务
            </li>
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    setEmailsFlag(
                      e.target.value.trim() !== "" &&
                        validateEmails.test(e.target.value)
                    );
                    return { ...formInfo, email: e.target.value };
                  })
                }
                placeholder="请输入您的邮箱"
                value={formInfo.email}
              />
            </li>
            <li
              style={{ visibility: emailsFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              正确填写邮箱，我们的销售才能更好的为您提供服务
            </li>
            <li className="contact-textarea">
              <textarea
                onChange={(e) =>
                  setFormInfo(() => {
                    if (e.target.value.length <= 100) {
                      return { ...formInfo, description: e.target.value };
                    } else {
                      return { ...formInfo };
                    }
                  })
                }
                placeholder="请输入应用场景or行业"
                value={formInfo.description}
              />
              <span>{formInfo.description.length || 0}/100</span>
            </li>
          </ul>
          <div className="agreement-box">
            <div className="my-checkbox">
              <input
                type="checkbox"
                onChange={(e) => {
                  setAgree(e.target.checked);
                }}
                checked={agree}
              />
              <div className="my-checkbox-inner">
                <img src={agree ? checkedIcon : noCheckIcon} alt="" />
              </div>
            </div>
            点击立即预约即代表我已同意
            <span
              className="read-span"
              onClick={() => {
                window.open(
                  "https://xianyuan.feishu.cn/docx/AmW2dxQWzoic9UxlsjgcudWqnbg"
                );
                // window.open("/platform-sso/user-agreement?file=userAgreement");
              }}
            >
              《用户协议》
            </span>
            <span
              className="read-span"
              onClick={() => {
                window.open(
                  "https://xianyuan.feishu.cn/docx/FQtIdOD8GoKHFXxZ3SbcO2RrnId"
                );
                // window.open(
                //   location.origin +
                //     "/platform-sso/user-agreement?file=privacyPolicy"
                // );
              }}
            >
              《隐私政策》
            </span>
          </div>
          <div className="submit-btn">
            <button
              style={{
                background: `${
                  validForm() ? "#2352D8" : "rgba(28, 41, 71, 0.45)"
                }`,
              }}
              onClick={onSubmit}
            >
              立即预约
            </button>
          </div>
        </dd>
      </dl>
    </div>
  );
};
export default AlterWindow;
