import React, { memo, useEffect, useState } from "react";
import "./index.less";
import "./media.less";

import Card from "./card";
import { images, videoImgs } from "../../importImgs";
import gif4 from "../../images/cj/2-5/big.png";
import useViewport from "../../../../../hooks/useViewport";
function getDivImage(divElement: any) {
  let imgurl = window
    .getComputedStyle(divElement, null)
    .getPropertyValue("background-image");
  return imgurl.substring(5, imgurl.lastIndexOf('"'));
}
const Main = memo(() => {
  const { isMobile } = useViewport();

  const mainArr = [
    {
      title: "人台图秒变虚拟模特",
      desc: "只需将衣服或者饰品穿戴到人台模特上，就一键生成各种风格虚拟模特，减少商业拍摄过程和成本，生成高质量的商品营销图",
      images: images[1],
      gif: "https://cdn.frontis.top/cdn-content/static/moxiaoxian/video/big2.mov",
      url: "https://frontis.top/productforce-website/productimage/human-figure-image",
      imgSrc: videoImgs[0],
    },
    {
      title: "真人模特换虚拟模特",
      desc: "只需提供真人模特商拍图，可支持切换全球各地域风格虚拟模特，快速生成新的商品海报和模特图，低成本同时适应国内电商和跨境电商场景",
      images: images[2],
      gif: "https://cdn.frontis.top/cdn-content/static/moxiaoxian/video/big3.mp4",
      url: "https://frontis.top/productforce-website/productimage/human-figure-image",
      imgSrc: videoImgs[1],
    },
    {
      title: "真人模特切换拍摄场景",
      desc: "只需提供一张模特棚拍图或者任意一张真人模特图，省去各种外景拍摄成本，快速生成各种契合主题和背景的高清模特效果图",
      images: images[3],
      gif: "https://cdn.frontis.top/cdn-content/static/moxiaoxian/video/big4.mp4",
      url: "https://frontis.top/productforce-website/productimage/model-photo-new",
      imgSrc: videoImgs[2],
    },
    {
      title: "商品图任意切换场景",
      desc: "产品融合能力能够保证图片生成的过程当中产品不会变形，同时自动调整光影效果，使得产品和背景融合得更加自然和谐，贴近实拍效果图，保证图片产出速度的同时，提高图片的质量。",
      images: images[0],
      gif: "https://cdn.frontis.top/cdn-content/static/moxiaoxian/video/big1.mp4",
      url: "https://frontis.top/productforce-website/productimage/ai-product-image",
      imgSrc: videoImgs[3],
    },
    {
      title: "即将上线",
      desc: "定制专属 AI模特",
      type: "ai",
      images: images[3],
      gif: gif4,
      imgSrc: gif4,
    },
  ];
  const [loadVideo, setLoadVideo] = useState(false);

  useEffect(() => {
    // console.log( document.getElementById('banner'));
    let divImg = document.querySelector("#banner"); //获取div元素
    let getImg = new Image(); //新建Image对象
    getImg.src = getDivImage(divImg); //给Image元素指定地址
    const timer = setInterval(() => {
      if (getImg.complete) {
        setLoadVideo(true);
        clearInterval(timer);
      }
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className={isMobile ? "mxx-main-mobile" : "mxx-main"}>
      <div className="title-box">
        <h4>一张图，一句话，让创意更快落地 </h4>
        {!isMobile && (
          <p>
            大幅降低电商平台商品拍摄成本，缩短电商平台商品上架时间，提高电商平台商品转化率
          </p>
        )}
      </div>
      <div className="card-warp">
        {mainArr.map((data) => {
          return <Card key={data.title} data={data} loadVideo={loadVideo} />;
        })}
      </div>
    </div>
  );
});
export default Main;
