import { FC, useState } from 'react';
import ContactFormMobile from '../contactFormMobile';
import ercode from '../../images/ercode.png';
import './media.less';

const ContactUsMobile: FC<any> = ({ setShowContact }) => {
    const [showContactForm, setShowContactForm] = useState<boolean>(false);

    return <div className='contact-us-mobile-modal'>
        <div className='contact-us-mobile'>
            <div className='item header'>
                <div className='header-title'>联系我们</div>
                <span className='iconfont icon-close' onClick={() => setShowContact(false)}></span>
            </div>
            <div className='item phone'>
                <div>电话</div>
                <div className='right-text'>010-87531591</div>
            </div>
            <div className='item email'>
                <div>邮箱</div>
                <div className='right-text'>xianyuan@frontis.ai</div>
            </div>
            <div className='item address'>
                <div>地址</div>
                <div className='right-text'>北京市海淀区未来科技大厦4层</div>
            </div>
            <div className='item code'>
                <div className='left'>官方客服</div>
                <img className='right-img' src={ercode} alt='' />
            </div>
            <div className='item contact' onClick={() => setShowContactForm(true)}>
                <div className='o-modal-btn'>申请试用</div>
            </div>
        </div>
        {showContactForm && <ContactFormMobile setShowContactForm={setShowContactForm} />}
    </div>
}

export default ContactUsMobile;