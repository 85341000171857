import { FC, memo } from "react";
import desc1 from "./images/desc1.svg";
import desc2 from "./images/desc2.svg";

import desc3 from "./images/desc3.svg";

import desc4 from "./images/desc4.svg";
import "./media.less";
import "./index.less";
import useViewport from "../../../../hooks/useViewport";
const data = [
  {
    title: "自主研发",
    desc: (
      <p>
        完全自主研发，大模型算法<br></br>
        技术可控，在电商、快消等核心场景<br></br>已实现可靠性验证
      </p>
    ),
    icon: desc4,
  },
  {
    title: "可信赖",
    desc: (
      <p>
        生成可解释、可验证、<br></br>
        可执行的商业洞察、 品参决策、创新<br></br>产品、精准营销的全生命周
      </p>
    ),
    icon: desc3,
  },
  {
    title: "易拓展",
    desc: (
      <p>
        基于大模型深度行业知识和数据融合和多<br></br>
        模态的全栈技术能力，支持顶层各类多轮<br></br>对话应用快速
      </p>
    ),
    icon: desc2,
  },
  {
    title: "全链条",
    desc: (
      <p>
        构建B2B2C的产品、设计、营销、<br></br>
        导购 等全链路端到端解决方案
      </p>
    ),
    icon: desc1,
  },
];
const Desc4: FC = memo(() => {
  const { isMobile } = useViewport();

  return (
    <div className={`${isMobile ? "desc4-box-mobile" : "desc4-box"}`}>
      {data.map((data) => {
        return (
          <div className="desc" key={data.title}>
            <img src={data.icon} alt="" />
            <h4>{data.title}</h4>
            {data.desc}
          </div>
        );
      })}
    </div>
  );
});
export default Desc4;
