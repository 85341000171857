import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { setHeader } from '../../config/setHeader';
import logo from './images/logo.svg';
import hello from './images/hello.png';
import kefu from './images/kefu.png';
import close from './images/close.png';
import bg from './images/bg.png';
import arrowGray from './images/arrow-right-gray.png';
import arrowWhite from './images/arrow-right-white.png';
import aGray from './images/arrow-gray.png';
import aWhite from './images/arrow-white.png';
import moxiaoxinLogo from './images/mxx-logo.png';
import xyLogo from './images/xy-logo.png';
import './index.less';

const Conference: FC = memo(() => {
  const [showModel, setShowModel] = useState(false);
  useEffect(() => {
    setHeader({
      title: '衔远科技，全球领先的智能化服务引领者',
      description: '衔远科技自研千亿参数大语言模型 ProductGPT 与多模态模型 ProductDiffusion 已经通过中央网信办备案，是国内唯一一家双模型备案的企业。 由 IEEE/CAAI Fellow、原京东集团高级副总裁/云与AI总裁/技术委员会主席、清华大学惠妍讲席教授/电子系长聘教授周伯文博士创立，是全球领先的致力于链接消费者与商品的人工智能公司。',
      keywords: '衔远科技,周伯文,摹小仙,MODI,国产AI大模型,AI营销,AI商拍, ProductGPT,ProductDiffusion,慕小仙',
    });
  }, []);
  return (
    <div className='conference-0425'>
      <div className='conference-content'>
        <div className='pl-100'>
          <img alt='' src={logo} className='logo' />
          <img alt='' src={hello} className='hello' />
          <h1 className='title'>欢迎探索衔远科技</h1>
          <p className='sub-title'>全球领先的智能化服务引领者，助力企业实现从商品洞察到营销创新的一站式解决方案</p>
        </div>
        <div className='content-container'>
          <img src={bg} alt='' className='container-bg' />
          <div className='ai-item ai-hotspot' onMouseEnter={() => document.querySelector('.ai-hotspot-popover')?.classList.add('active')} onMouseLeave={() => document.querySelector('.ai-hotspot-popover')?.classList.remove('active')}>
            <div className='flex-align-center ai-hotspot-title'>
              热点营销
              <img alt='' className='arrow arrow-gray' src={arrowGray} />
              <img alt='' className='arrow arrow-white' src={arrowWhite} />
            </div>
            <div className='ai-hotspot-popover'>
              <AiPopover logo={moxiaoxinLogo} title='热点营销' description='AI 赋能营销行业革新，提供市场洞察、智能生成、高效分发全链路营销智能创作，引领高效营销生产新方式，实现营销降本增效' url='https://www.moxiaoxian.art/productforce-website/productimage/MoTT' />
            </div>
          </div>
          <div className='ai-item ai-model'>
            <div className='flex-align-center ai-model-title'>
              <div>衔远自研大模型<br/>ProductGPT & ProductDiffusion</div>
              <img alt='' className='arrow arrow-gray' src={arrowGray} />
              <img alt='' className='arrow arrow-white' src={arrowWhite} />
            </div>
            <div className='ai-model-popover'>
              <AiPopover logo={xyLogo} title='衔远自研大模型' subTitle='ProductGPT & ProductDiffusion' description='AI 衔远科技自研大语言模型 ProductGPT 与多模态大模型 ProductDiffusion ，是国内少有的一家同时通过中央网信办双模型备案的企业。衔远自研大模型在保持泛化性的基础上解决专业化问题，利用场景和数据持续打磨底层模型，可自我评估与迭代并实现通用数据+全链路专家级数据飞轮，能帮助企业在市场洞察、知识管理、创意策划、内容生产、营销转化及商品创新等领域进行深度赋能。' />
            </div>
          </div>
          <div className='ai-item ai-shop'>
            <div className='flex-align-center ai-shop-title'>
              AI 商拍
              <img alt='' className='arrow arrow-gray' src={arrowGray} />
              <img alt='' className='arrow arrow-white' src={arrowWhite} />
            </div>
            <div className='ai-shop-popover'>
              <AiPopover logo={moxiaoxinLogo} title='AI 商拍' description='AI 模特商拍平台，可以帮助服装商家省去模特拍摄的成本，一键生成高颜值AI模特服装图，极大的降低服装商拍成本以及提高商品转化利润' url='https://www.moxiaoxian.art/productforce-website/productimage/human-figure-image' />
            </div>
          </div>
          <div className='ai-video'>AI 视频</div>
        </div>
        <p className='company-info'>北京衔远科技有限公司 京ICP备2022014486号-1 <span onClick={() => setShowModel(true)}>关于我们</span></p>
        <p className='copyright'>Copyright © www.frontis.cn, All Rights Reserved.</p>
      </div>
      {showModel && (
        <div className='about-us-model'>
          <div className='about-us-model-title'>
            <div className='left'>关于我们</div>
            <img alt='' className='right' src={close} onClick={() => setShowModel(false)} />
          </div>
          <div className='about-us-model-content'>
            <h3>公司介绍</h3>
            <p className='indent'>衔远科技 由 IEEE/CAAI Fellow、原京东集团高级副总裁/云与AI总裁/技术委员会主席、清华大学惠妍讲席教授/电子系长聘教授周伯文博士创立，核心团队成员来自京东、腾讯、百度、微软等知名企业，具备产业深度know-how与数智化平台建设成功经验，是全球领先的致力于链接消费者与商品的人工智能公司。</p>
            <p className='indent'>衔远科技自研品商AI大模型在具备通用能力的基础上，更擅长理解人与商品，通过ProductGPT和ProductDiffusion为企业与消费者以生成式人工智能帮助从商品洞察、定位、设计、研发到营销的创新。</p>
            <p className='indent'>目前已应用场景包括食品饮料、3C、家电、服装等行业，服务京东、伊利等众多头部客户。</p>
            <h3>联系我们</h3>
            <p>地址：北京市海淀区未来科技大厦4层</p>
            <p>邮箱：xianyuan@frontis.ai</p>
            <img alt='' src={kefu} className='kefu' />
          </div>
        </div>
      )}
    </div>
  )
})
export default Conference;

const AiPopover: FC<{
  logo?: any;
  title?: string;
  subTitle?: string;
  description?: string;
  url?: string;
}> = memo(({ logo, title, subTitle, description, url }) => {
  const openUrl = useCallback(() => {
    if (!url) return;
    window.open(url, '_blank')
  }, [url])
  return (
    <div className='ai-popover'>
      <img alt='' src={logo} className='ai-logo' />
      <h2>{title}</h2>
      {subTitle && <h3>{subTitle}</h3>}
      <p>{description}</p>
      <div className='footer'>
        <div className={`btn${url ? '' : ' disabled'}`} onClick={openUrl}>
          {url? '立即体验' : '敬请期待'}
          <img alt='' src={aGray} className='a-gray' />
          <img alt='' src={aWhite} className='a-white' />
        </div>
      </div>
    </div>
  )
})