import { useState, createRef } from "react";
import AlterWindow from "../../../../layout/components/alterWindow";
import ContactUsMobile from "../../../../layout/components/contactMobile";
import useViewport from "../../../../hooks/useViewport";
import "./media.less";
import "./index.less";
import Content from "../content";

const WithPictures = () => {
  const { isMobile } = useViewport();
  const [alterFlag, setAlterFlag] = useState(false);
  const box = createRef<HTMLCanvasElement>();
  // const app = useRef<Application>();
  // useEffect(() => {
  //   app.current = new Application(box.current as HTMLCanvasElement);
  //   app.current.setZoom(0.5);
  //   // eslint-disable-next-line
  // }, []);
  return (
    <div className={`${isMobile ? "with-pictures-mobile" : "with-pictures"}`}>
      {!isMobile && (
        <div className="with-pictures-runtime-box">
          <canvas ref={box} className="with-pictures-runtime"></canvas>
        </div>
      )}
      <div className="pictures-content">
      <Content
          title="开启品商大模型之旅 "
          desc={
            <p className="desc">
          加速赋能企业每一位员工 申请试用，与衔远一起探索数智化
            </p>
          }
          style={{ marginTop: isMobile ? ".16rem" : ".6rem" }}
          />
        <button className={isMobile ? 'contact-btn-mobile' : 'contact-btn'} onClick={() => setAlterFlag(true)}>
          <span>联系我们</span>
        </button>
      </div>
      {alterFlag ? (
        isMobile ? (
          <ContactUsMobile setShowContact={setAlterFlag} />
        ) : (
          <AlterWindow setAlterFlag={setAlterFlag} />
        )
      ) : null}
    </div>
  );
};
export default WithPictures;
