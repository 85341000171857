import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Toaster } from 'react-hot-toast';
import Routes from "../router";
import useViewport from "../hooks/useViewport";
import useNavStyle from "../hooks/useNavStyle";
import { BASE_ROUTE_NAME } from '../config/index';
import "./index.less";
import "./media.less";

const Layout = () => {
  const { isMobile } = useViewport();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  useNavStyle();
  useEffect(() => {
    if (pathname !== `${BASE_ROUTE_NAME}/`) history.push(`${BASE_ROUTE_NAME}/`)
  }, [pathname, history]);
  return (
    <div className={`${isMobile ? "layout-app-mobile" : "layout-app"}`}>
      <main>{renderRoutes(Routes)}</main>
      <Toaster />
    </div>
  );
};
export default Layout;
