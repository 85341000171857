import useViewport from "../../../../hooks/useViewport";
import banner from './images/banner-content-img.jpg'
import "./media.less";
import "./index.less";

const Banner = () => {
  const { isMobile } = useViewport();
  return (
    <div
      className={`${isMobile ? "banner-resource-mobile" : "banner-resource"}`}
    >
      <img className="resource-banner" src={banner} alt="行业最新前沿资讯分享" />
    </div>
  );
};
export default Banner;
