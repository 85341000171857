import { FC, memo } from "react";
import img from "./images/boss.svg";
import imgMobile from "./images/boss-mobile.svg";

import "./media.less";
import "./index.less";
import useViewport from "../../../../hooks/useViewport";

const Boss: FC = memo(() => {
  const { isMobile } = useViewport();

  return (
    <div className={`${isMobile ? "boss-box-mobile" : "boss-box"}`}>
      <div className="boss-content">
        <img src={isMobile?imgMobile:img} alt="" />
      </div>
    </div>
  );
});
export default Boss;
