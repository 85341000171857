import useViewport from "../../../../hooks/useViewport";
import "./media.less";
import "./index.less";
import Content from "../content";

const WithPicturesCenter = () => {
  const { isMobile } = useViewport();
  return (
    <div
      className={`${
        isMobile ? "with-pictures-center-mobile" : "with-pictures-center"
      }`}
    >
      <div className="pictures-content">
        <Content
          title="探索实践ProductGPT "
          desc={
            <p className="desc">
              基于商品供应链大模型的企业级多轮对话平台， 赋能每一位员工
            </p>
          }
          style={{ marginTop: isMobile ? "0.14rem" : "60px" }}
        />
      </div>
    </div>
  );
};
export default WithPicturesCenter;
