import useViewport from "../../../../hooks/useViewport";
import "./media.less";
import "./index.less";

const ProgramAdvantages = () => {
  const { isMobile } = useViewport();

  return (
    <div className="program-advantages-warp">
      <div
        className={`${
          isMobile ? "program-advantages-mobile" : "program-advantages"
        }`}
      >
        <div className="advantages-content">
          <h3>解决方案特点&优势</h3>

          <div className="advantages-list">
            <ul>
              <li
                style={{
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%, #FFFFFF 100%)",
                }}
              >
                <div className="li-content">
                  <h4>数据全面</h4>
                  {isMobile ? (
                    <p>
                      拥有全面的行业数据库；国内外主流电商全覆盖：京东、淘宝、天猫、亚马逊、速卖通等数十家；行业内容和社媒平台：抖音、B站、小红书、知乎、微博、微信等。
                    </p>
                  ) : (
                    <>
                      <p> · 拥有全面的行业数据库 </p>
                      <p>
                        ·
                        国内外主流电商全覆盖：京东、淘宝、天猫、亚马逊、速卖通等数十家
                      </p>
                      <p>
                        ·
                        行业内容和社媒平台：抖音、B站、小红书、知乎、微博、微信等
                      </p>
                    </>
                  )}
                </div>
              </li>
              <li
                style={{
                  background:
                    "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%, #FFFFFF 100%)",
                }}
              >
                <div className="li-content">
                  <h4>技术领先</h4>
                  {isMobile ? (
                    <p>
                      全球领先的数智化AI技术；多模态感知、认知技术/OCR/NLP/语言转文本可支持复杂文本关系识别，图片、视频识别。
                    </p>
                  ) : (
                    <>
                      <p> · 全球领先的数智化AI技术</p>
                      <p>
                        ·
                        多模态感知、认知技术/OCR/NLP/语言转文本可支持复杂文本关系识别，图片、视频识别
                      </p>
                    </>
                  )}
                </div>
              </li>
            </ul>
            <ul>
              <li
                style={{
                  background:
                    "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%, #FFFFFF 100%)",
                }}
              >
                <div className="li-content">
                  <h4>业务理解</h4>
                  {isMobile ? (
                    <p>
                      解决方案涵盖食品饮料行业的市场、品牌、消费者洞察，产品创新研发等多个业务场景；针对食品饮料行业所处各个阶段，提供不同的数据解决方案，帮助客户解决增长的核心问题。
                    </p>
                  ) : (
                    <>
                      <p>
                        ·
                        解决方案涵盖食品饮料行业的市场、品牌、消费者洞察，产品创新研发等多个业务场景
                      </p>
                      <p>
                        ·
                        针对食品饮料行业所处各个阶段，提供不同的数据解决方案，帮助客户解决增长的核心问题
                      </p>{" "}
                    </>
                  )}
                </div>
              </li>
              <li
                style={{
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%, #FFFFFF 100%)",
                }}
              >
                <div className="li-content">
                  <h4>行业深耕</h4>
                  <p>
                    {!isMobile && <>·</>}
                    深耕食品饮料行业，服务多家行业头部客户，拥有备受认可的服务能力和经验，为食品饮料行业的发展提供依据，助力食品饮料企业把握行业风向
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProgramAdvantages;
