import icon1 from "./images/welfare-icon-1.svg";
import icon2 from "./images/welfare-icon-2.svg";
import icon3 from "./images/welfare-icon-3.svg";
import icon4 from "./images/welfare-icon-4.svg";
import icon5 from "./images/welfare-icon-5.svg";
import icon6 from "./images/welfare-icon-6.svg";
import icon7 from "./images/welfare-icon-7.svg";
export const welfareData = [
  {
    icon: icon1,
    name: "全员期权",
  },
  {
    icon: icon2,
    name: "补充医疗",
  },
  {
    icon: icon3,
    name: "全额五险一金",
  },
  {
    icon: icon4,
    name: "年度体检",
  },
  {
    icon: icon5,
    name: "加班打车",
  },
  {
    icon: icon6,
    name: "下午茶",
  },
  {
    icon: icon7,
    name: "培训团建",
  },
];

export interface IJobItem {
  additional?: string;
  createTime?: string;
  departmentId?: number;
  departmentName?: string;
  id?: string;
  jobName?: string;
  requirement?: string;
  responsibility?: string;
}
export interface IDepItem {
  createTime?: string;
  departmentName?: string;
  id?: string;
  updateTime?: string;
}
export const depData = [
  {
    name: "岗位职责",
    key: "responsibility",
  },
  {
    name: "任职要求",
    key: "requirement",
  },
  {
    name: "加分项",
    key: "additional",
  },
];
