import { useEffect, useState } from "react";
import useViewport from "../../../../hooks/useViewport";
import AlterWindow from "../../../../layout/components/alterWindow";
import ContactUsMobile from "../../../../layout/components/contactMobile";
import "./media.less";
import "./index.less";
import cardIcon from "./images/guanfangzixun.svg";
import axios from "axios";
import { REACT_APP_REQUEST_URL } from "../../../../env";
import { BASE_ROUTE_NAME } from "../../../../config";
import { useHistory } from "react-router-dom";
import { scrollTop } from "../../../../utils/scroll";
interface INewItem {
  content?: string | null;
  coverPlan?: string;
  createTime?: string;
  createUser?: string;
  id?: string | number;
  readCount?: string;
  region?: { desc?: string; code?: string };
  sort?: string | number;
  status?: { desc?: string; code?: string };
  subTitle?: string;
  summary?: string;
  title?: string;
  top?: boolean;
  updateTime?: string;
  updateUser?: string;
}
const HomeBanner = () => {
  const { isMobile } = useViewport();
  const [alterFlage, setAlterFlag] = useState(false);
  const [news, setNews] = useState<INewItem[]>([]);

  const getNewList = () => {
    axios
      .get(
        `${REACT_APP_REQUEST_URL}/newsinfo?status=PUBLISHED&pageNum=1&pageSize=3&region=NEWS_LETTER`
      )
      .then((res: any) => {
        const records =
          res?.data?.data?.records.filter((item: any) => item.status.code) ||
          [];
      
        setNews(records.slice(0, 3));
      });
  };

  useEffect(() => {
    getNewList();
  }, []);
  const history = useHistory();

  return (
    <div className={`${isMobile ? "home-banner-mobile" : "home-banner"}`}>
      <div className="banner-content">
        <h3 className="title">全球首个商品供应链大模型</h3>
        <span className="desc">
          衔远科技始创于2021年，是全球领先的致力于链接消费者与商品的人工智能公司。
          <br></br>
          公司自研AI大模型，数百亿级参数规模与独特
          的训练让大模型在具备通用能力的基础上，更擅长理解人与商品。<br></br>
          通过ProductGPT，以生成式人工智能帮助企业与消费者实现从商品洞察、定位、设计、研发到营销的创新。
        </span>
      </div>
      <div className="card-box">
        {news.map((news) => {
          return (
            <div
              key={news.id}
              className="news-card"
              onClick={() => {
                history.push(`${BASE_ROUTE_NAME}/news/detail/` + news.id);
                scrollTop();
              }}
            >
              <img src={cardIcon} alt="" />
              <p>{news.title}</p>
              <span>{news.summary}</span>
            </div>
          );
        })}
      </div>
      {alterFlage ? (
        isMobile ? (
          <ContactUsMobile setShowContact={setAlterFlag} />
        ) : (
          <AlterWindow setAlterFlag={setAlterFlag} />
        )
      ) : null}
    </div>
  );
};

export default HomeBanner;
