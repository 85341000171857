import { useState } from "react";
import AlterWindow from "../../layout/components/alterWindow";
import useViewport from "../../hooks/useViewport";
import ContactUsMobile from "../../layout/components/contactMobile";
import "./index.less";
import "./media.less";

const Immediately = () => {
  const { isMobile } = useViewport();
  const [alterFlage, setAlterFlag] = useState(false);
  return (
    <div className={isMobile ? "immediately-media" : "immediately-modal"}>
      <p>
        立刻申请，打造企业专属的AI大模型{" "}
        {isMobile && (
          <div
            className={isMobile ? "product-btn-mobile" : "product-btn"}
            onClick={() => setAlterFlag(true)}
          >
            <span>申请试用</span>
          </div>
        )}
      </p>
      {!isMobile && (
        <div
          className={isMobile ? "product-btn-mobile" : "product-btn"}
          onClick={() => setAlterFlag(true)}
        >
          <span>申请试用</span>
        </div>
      )}

      {alterFlage ? (
        isMobile ? (
          <ContactUsMobile setShowContact={setAlterFlag} />
        ) : (
          <AlterWindow setAlterFlag={setAlterFlag} />
        )
      ) : null}
    </div>
  );
};

export default Immediately;
