import { FC, memo, useEffect, useState } from "react";
import "./index.less";
import useViewport from "../../../../../hooks/useViewport";
import codeChart from "../../images/moxiaoxian-kefu.png";
import "./index.less";

interface ICard {
  data: RootObject;
  loadVideo: boolean;
}
interface RootObject {
  title: string;
  desc: string;
  images: string[];
  type?: string;
  url?: string;
  imgSrc?: string;
  gif: string;
}
const Card: FC<ICard> = memo(({ data, loadVideo }) => {
  const AiFlag = data.type === "ai";
  const { isMobile } = useViewport();
  const [showChart, setShowChart] = useState(false);
  function doPlay() {
    (window as any).WeixinJSBridge.invoke("getNetworkType", {}, function () {
      var video1: any = document.getElementById(data.title);

      video1?.play();
    });
  }
  useEffect(() => {
    if ((window as any).WeixinJSBridge) {
      doPlay();
    } else {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          doPlay();
        },
        false
      );
    }
  });
  return (
    <div>
      <div className={`card ${AiFlag ? "ai-card" : ""}`}>
        <div className="left-box">
          <h4>{data.title}</h4>
          <p>{data.desc}</p>
          {AiFlag ? (
            <span className="moxiaoxian-button ai">敬请期待</span>
          ) : (
            <div className="moxiaoxian-button-box">
              <div
                className="moxiaoxian-button "
                onClick={() => {
                  if (isMobile) {
                    setShowChart(!showChart);
                  }
                }}
              >
                联系我们
              </div>
              {/* 摹小仙客服 */}
              <dl
                className="moxiaoxian-code-chart"
                style={
                  isMobile ? { display: showChart ? "block" : "none" } : {}
                }
              >
                <dt>- 官方客服 -</dt>
                <dd>
                  <img src={codeChart} alt="官方客服二维码" />
                </dd>
              </dl>
            </div>
          )}
        </div>
        <div className="right-box">
          {AiFlag || !loadVideo ? (
            <img className="gif-img" src={`${data.imgSrc}`} alt="" />
          ) : (
            <video
              id={data.title}
              className="gif-img"
              autoPlay
              loop
              muted
              playsInline
              poster={data.imgSrc}
              x5-video-player-type="h5"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              x5-video-player-fullscreen="true"
              src={data.gif}
            ></video>
          )}

          <div className="scroll">
            <ul
              className="list"
              style={{
                width: isMobile
                  ? data.images.length * 15 + "vw"
                  : data.images.length * 112 + "px",
              }}
            >
              {/* <div> */}
              {!AiFlag &&
                [...data.images, ...data.images].map((image, i) => {
                  return <img key={i} src={`${image}`} alt="" />;
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Card;
