import FastConsumptionChallenges from "../components/fastConsumptionChallenges";
import FastConsumptionSolution from "../components/fastConsumptionSolution";
import FastConsumptionAdvantages from "../components/fastConsumptionAdvantages";
import useScrollToTop from "../../../hooks/useScrollToTop";
import Immediately from "../../../components/immediately";
import "./index.less";
import Banner from "../mxx/components/banner";
import banner from './banner.jpg'
const FastConsumption = () => {
  useScrollToTop();

  return (
    <div className="solution-page">
      <Banner
        title={["快消零售行业", "用AI重塑商品价值"]}
        desc={[
          "基于衔远ProductGPT完成智能洞察分析，通过社媒数据进行舆情监测和竞品调研，",
          "发现蓝海机会，并通过智能营销快速占领市场。",
        ]}
        style={{ background: `url(${banner})  no-repeat right center`,backgroundSize:'100% auto'}}
        bgColor = 'linear-gradient(293deg, rgba(13,8,8,0.00) 1%, rgba(13,8,8,0.86) 62%, #0D0808 89%)'
      />
      {/* 业务挑战 */}
      <FastConsumptionChallenges />
      {/* 食品行业数字化转型解决方案 */}
      <FastConsumptionSolution />
      {/* 解决方案优势 */}
      <FastConsumptionAdvantages />
      <Immediately />
    </div>
  );
};
export default FastConsumption;
