import axios from "axios";
import "./index.less";
import useViewport from "../../hooks/useViewport";
import { depData, IDepItem, IJobItem, welfareData } from "./data";
import React, {
  createRef,
  FC,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { REACT_APP_REQUEST_URL } from "../../env";
import { useSetHeader } from "../../hooks/useSetHeader";

const JoinUs: FC = () => {
  const { isMobile } = useViewport();
  useSetHeader({});
  const [jobActive, setJobActive] = useState<string>();
  const [departments, setDepartments] = useState<IDepItem[]>([]);
  const [activeDepartment, setActiveDepartment] = useState<string>();
  const [jobList, setJobList] = useState<Record<string, IJobItem[]>>({});
  const [jobContent, setJobContent] = useState<IJobItem>(); //职责
  const joinDepMain = createRef<HTMLDivElement>();
  const fetchScp = async (id: string) => {
    setJobActive(id);
    axios(`${REACT_APP_REQUEST_URL}/job/${id}`).then((res) => {
      const data = res.data.data;
      setJobContent(data);
      if (data.id) setJobActive(data.id);
    });
  };
  useLayoutEffect(() => {
    setTimeout(() => {
      joinDepMain.current?.scrollTo(0, 0);
    }, 0);
    // eslint-disable-next-line
  }, [jobContent]);
  const fetchJob = async () => {
    const res = await axios(`${REACT_APP_REQUEST_URL}/department`);
    setDepartments(res.data.data);
    if (res.data.data.length) {
      const jobRes = await Promise.all(
        res.data.data.map((child: IDepItem) =>
          axios.get(`${REACT_APP_REQUEST_URL}/job`, {
            params: {
              departmentId: child?.id,
              departmentName: child?.departmentName,
              keyword: "",
              pageNum: "1",
              pageSize: "100",
            },
          })
        )
      );
      const _departments: Record<string, IJobItem[]> = {};
      let _activeDepartment = "";
      jobRes.forEach((data, index) => {
        if (!_activeDepartment && data.data.data.records.length) {
          _activeDepartment = res.data.data[index].id;
          fetchScp(data.data.data.records[0].id);
        }
        _departments[res.data.data[index].id] = data.data.data.records;
      });
      setJobList(_departments);
      setActiveDepartment(_activeDepartment);
      setJobActive(jobRes[0].id);
    }
  };
  useEffect(() => {
    fetchJob();
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className={
        isMobile
          ? "join-us-page join-us-page-phone"
          : "join-us-page join-us-page-pc"
      }
    >
      <div className="join-banner">加入我们</div>
      <div className="join-job">
        <h3 className="join-job-short">全职{"&"}实习任你选</h3>
        <h2 className="join-job-title">热招岗位</h2>
      </div>
      <div className="join-dep">
        <div className="join-dep-menu">
          {departments.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <div
                  className={`join-dep-name ${
                    (item.id === activeDepartment && "dep-active") || ""
                  }`}
                  onClick={() => {
                    if (!isMobile) return;
                    setActiveDepartment(item.id);
                    if (jobList[item.id as string]?.[0]?.id)
                      fetchScp(jobList[item.id as string]?.[0]?.id as string);
                  }}
                >
                  {item.departmentName}
                </div>
                <ul className="join-dep-list">
                  {jobList[item.id as string]?.map((itemChild) => (
                    <li
                      key={itemChild.id}
                      className={itemChild.id === jobActive ? "job-active" : ""}
                      onClick={() => fetchScp(itemChild.id as string)}
                    >
                      {itemChild.jobName}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            );
          })}
        </div>
        <div className="join-dep-main" ref={joinDepMain}>
          <div className="join-dep-main-title pc-show-span">
            <p>{jobContent?.jobName}</p>
            <span>联系邮箱：hr-public@frontis.cn</span>
          </div>
          {depData.map((child) =>
            jobContent?.[child.key as keyof IJobItem] ? (
              <React.Fragment key={child.key}>
                <div className="join-dep-main-name">{child.name}</div>
                <div
                  className="join-dep-main-scp"
                  dangerouslySetInnerHTML={{
                    __html:
                      (jobContent?.[child.key as keyof IJobItem] as string) ||
                      "",
                  }}
                ></div>
              </React.Fragment>
            ) : null
          )}
        </div>
      </div>
      <div className="join-welfare">
        <h3 className="join-welfare-scp pc-show-span">更多福利等你发现</h3>
        <h2 className="join-welfare-title">公司福利一览</h2>
        <ul className="join-welfare-list">
          {welfareData.map((child) => (
            <li key={child.name}>
              <img src={child.icon} alt={child.name} />
              <p>{child.name}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="join-development">
        <h2 className="join-development-title">
          衔远快速发展中<span className="pc-show-span">，</span>
          <br className="phone-show-span" />
          期待您的加入！
        </h2>
        <p className="join-development-scp">
          在未来十年最有确定性的赛道上，我们期待您的加入，让我们一起努力用技术为社会创造更多的价值！公司提供极具竞争力的薪酬待遇和广阔的成长空间，让每一位衔远人和公司一起分享高速成长的回报！
        </p>
      </div>
    </div>
  );
};
export default JoinUs;
