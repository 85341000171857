import keyboard from "./images/keyboard.png";
import computer from "./images/computer.png";
import monitor from "./images/monitor.png";
import phone from "./images/phone.png";

import useViewport from "../../../../hooks/useViewport";
import "./media.less";
import "./index.less";

const FastConsumptionChallenges = () => {
  const { isMobile } = useViewport();

  return (
    <div
      className={`${
        isMobile
          ? "business-challenges-mobile"
          : "business-consumption-challenges"
      }`}
    >
      <div className="challenges-content">
        <h2>业务挑战</h2>
        <div className="challenges-list">
          <ul>
            <li>
              <div
                className="list-img"
                // style={{ backgroundImage: `url(${keyboard})` }}
              >
                <img src={keyboard} alt="" />
                <div className="list-content-warp">
                  <b>需求日益增多</b>
                  <b>应对缓慢</b>
                </div>
              </div>
              <div className="list-text">
                消费者关注的口味和口感越发多元化，行业发展的速度之快，让根深蒂固思维方式或者靠单一力量转型的公司有点力不从心
              </div>
            </li>
            <li>
              <div
                className="list-img"
                // style={{ backgroundImage: `url(${computer})` }}
              >
                <img src={computer} alt="" />
                <div className="list-content-warp">
                  <b>产品同质化严重</b>
                  <b>行业竞争激烈</b>
                </div>
              </div>
              <div className="list-text">
                产品同质化、渠道同质化、推广同质化和模式同质化，市场竞争压力越来越大，实现快消行业特性的“高频”和“刚需”难度骤增
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <div
                className="list-img"
                // style={{ backgroundImage: `url(${monitor})` }}
              >
                <img src={monitor} alt="" />
                <div className="list-content-warp">
                  <b>渠道分散</b>
                  <b>获取消费者反馈难</b>
                </div>
              </div>
              <div className="list-text">
                渠道分散化、多极化，品牌和终端用户距离太远，很难获得消费者一手反馈，难以确立贴合消费者口味的品牌认知和定位
              </div>
            </li>
            <li>
              <div
                className="list-img"
                // style={{ backgroundImage: `url(${phone})` }}
              >
                <img src={phone} alt="" />
                <div className="list-content-warp">
                  <b>产品战略 </b>
                  <b>规划混乱</b>
                </div>
              </div>
              <div className="list-text">
                产品规划缺乏清晰的想法和战略，被迫频繁上新导致SKU相应增多，但是又没有精力去打造超级大单品
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default FastConsumptionChallenges;
