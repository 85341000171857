import { FC, ReactElement, memo } from "react";
import "./media.less";

import "./index.less";
import useViewport from "../../../../hooks/useViewport";
interface IContent {
  title: string;
  desc: ReactElement<any, any>;
  style: object;
}
const Content: FC<IContent> = memo(({ title, desc, style }) => {
  const { isMobile } = useViewport();

  return (
    <div
      className={`${isMobile ? "content-box-mobile" : "content-box"}`}
      style={style}
    >
      <h2>{title}</h2>
      {desc}
    </div>
  );
});
export default Content;
