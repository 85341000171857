import React from 'react';
import { memo } from 'react';
import './index.less';
import Banner from '../banner';
import Main from '../mian';

const HomePage = memo(() => {
  return (
    <div>
      <Banner title={['时尚潮流行业','AI模特商拍领先者']} desc={['用AIGC的技术帮助客户以极高的效率和极低的成本',' 解决传统商品图拍摄长周期和高成本问题，生成高可用的产品营销图']}/>
      <Main />
    </div>
  );
});

export default HomePage;
