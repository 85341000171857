
import useScrollToTop from "../../../hooks/useScrollToTop";
import "./index.less";
import HomePage from "./components/home";

const Mxx = () => {
  useScrollToTop();
  return (
    <div>
      <HomePage/>
    </div>
  );
};
export default Mxx;
