import { FC, memo, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import useViewport from '../../../../hooks/useViewport';
import { scrollTop } from '../../../../utils/scroll';
import { BASE_ROUTE_NAME } from "../../../../config/index";
import { REACT_APP_REQUEST_URL } from "../../../../env";
import './index.less';
import './media.less';

const ARTICLE_TAG_MAPPER = [
  { label: '全部文章', value: '' },
  { label: '衔远荣誉', value: 'honoraryTitles' },
  { label: 'AI新周刊', value: 'aiNewWeekly' },
  // { label: '技术干货', value: 'technicalDryGoods' },
  { label: '媒体报道', value: 'mediaCoverage' },
];
const Articles: FC = memo(() => {
  const { isMobile } = useViewport();
  const [active, setActive] = useState('')
  const [newsList, setNewsList] = useState<Array<any>>([]);

  const getNewList = useCallback(() => {
    let url = `${REACT_APP_REQUEST_URL}/newsinfo?status=PUBLISHED&pageNum=1&pageSize=100`;
    if (active) url += `&tag=${active}`;
    axios
      .get(url)
      .then((res) => {
        const records =
          res?.data?.data?.records.filter((item: any) => item.status.code) ||
          [];
        setNewsList(records);
      });
  }, [active]);

  useEffect(() => {
    getNewList();
  }, [getNewList]);

  return (
    <div className={`articles-component ${isMobile ? 'articles-component-mobile' : ''}`}>
      <div className='article-tabs'>
        {ARTICLE_TAG_MAPPER.map(item => {
          return (
            <div
              key={item.value}
              className={`article-tab${active === item.value ? ' active' : ''}`}
              onClick={() => setActive(item.value)}
            >
              {item.label}
            </div>
          )
        })}
      </div>
      <div className='article-wrapper'>
        {newsList.length ? (
          <div className='article-lists'>
            {newsList.map(item => {
              return <Article key={item.id} article={item} />
            })}
          </div>
        ) : (
          <div className='article-empty'>暂无内容</div>
        )}
      </div>
    </div>
  )
})

const Article: FC<{
  article: any
}> = memo(({ article }) => {
  const history = useHistory();
  const { isMobile } = useViewport();

  return (
    <div className='article-item' onClick={() => {
      history.push(`${BASE_ROUTE_NAME}/news/detail/` + article.id)
      scrollTop()
    }}>
      <img className='cover' src={article.coverPlan} alt="" />
      <div className='right'>
        <div className='title'>{article.title}</div>
        <div className='summary'>{article.summary}</div>
        <div className='infos'>
          <div className='date'>
            <span className='iconfont icon-time'></span>
            {isMobile?article.createTime?.substring(0,10) :article.createTime}
          </div>
          <div className='read-num'>
            <span className='iconfont icon-eyes'></span>
            {article.readCount}
          </div>
        </div>
      </div>
    </div>
  )
})

export default Articles;