import "./media.less";
import "./index.less";
import scjd from "./images/scjd.svg";
import cpcx from "./images/cpcx.svg";

import nrsj from "./images/nrsj.svg";

import touzizhe from "./images/touzizhe.svg";

import yxch from "./images/yxch.svg";

import zndg from "./images/zndg.svg";
import useViewport from "../../../../hooks/useViewport";

const data = [
  [
    {
      title: "市场解读助手",
      list: [
        "发现&解读细分市场特性趋势，提供建议",
        "市场消费者反馈及趋势解读",
        "解决市场&用户&销售&竞品产品趋势",
        "销售趋势特性挖掘与解读，知彼知己",
      ],
      icon: scjd,
    },
    {
      title: "产品创新助手",
      list: [
        "解读产品&客群的趋势/功能性的特点挖掘",
        "挖掘产品的市场流行元素，发现更多灵感",
        "基于产品的创新趋势建议和生成创新策略",
      ],
      icon: cpcx,
    },
    {
      title: "内容设计助手",
      list: [
        "基于场景和创新点的快速生成内容",
        "通过AIGC的文生图，图生图等内容设计能力，覆盖商品营销图、人台图、模特图等内容生成",
      ],
      icon: nrsj,
    },
  ],
  [
    {
      title: "营销策划助手",
      list: [
        "营销策略建议和生成",
        "基于大数据的细分销售场景挖掘和解读",
        "解读消费者流行趋势场景，生成营销策划",
      ],
      icon: yxch,
    },
    {
      title: "智能导购助手",
      list: [
        "旅游场景行程推荐",
        "基于消费者画像的商户推荐",
        "基于消费者需求的商品 /服务/权益推荐",
        "基于用户行为的线索生成",
      ],
      icon: zndg,
    },
    {
      title: "知识小秘书",
      list: [
        "解读市场研究报告和行业数据，总结关键结论",
        "对市场及行研报告中模式，指标及趋势等元素解析",
        "关于所提供数据的特定问题的问题进行回答",
        "基于解读的报告及内容撰写",
      ],
      icon: touzizhe,
    },
  ],
];
const Card = () => {
  const { isMobile } = useViewport();

  return (
    <div className={`${isMobile ? "home-card-warp-mobile" : "home-card-warp"}`}>
      {isMobile ? (
        <div className="card-box">
          {data.map((data, i) => {
            return data.map((data, i) => {
              return (
                <div className="news-card" key={i}>
                  <img src={data.icon} alt="" />
                  <p>{data.title}</p>
                  <ul>
                    {data.list.map((str, i) => {
                      return (
                        <li key={str}>
                          <span className="iconfont icon-check"></span>
                          <span className="text">{str}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            });
          })}
        </div>
      ) : (
        data.map((data, i) => {
          return (
            <div className="card-box" key={i}>
              {data.map((data, i) => {
                return (
                  <div className="news-card" key={i}>
                    <img src={data.icon} alt="" />
                    <p>{data.title}</p>
                    <ul>
                      {data.list.map((str, i) => {
                        return (
                          <li key={str}>
                            <span className="iconfont icon-check"></span>
                            <span className="text">{str}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </div>
  );
};

export default Card;
