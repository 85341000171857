import experiencechart from './images/experiencechart.png';
import solutionchart from './images/solutionchart.png';
import experiencechartMedia from './images/experiencechart-media.png';
import solutionchartMedia from './images/solutionchart-media.png';
import publicSentiment from './images/c.png';
import publicSentimentMedia from './images/mediaC.png';
import marketing from './images/d.png';
import marketingMedia from './images/mediaD.png';

import useViewport from '../../../../hooks/useViewport';
import './media.less';
import './index.less';

const CustomizedSolution = () => {
    const { isMobile } = useViewport();

    return <div className={`${isMobile ? 'customized-solution-mobile' : 'customized-Consumption-solution'}`}>
        <div className='customized-content'>
            <h2>快消零售行业数字化转型解决方案</h2>
            <div className='solution-charts'>
                <div>
                    <h3>a、洞察分析</h3>
                    <p>基于快消零售行业品类特征和行业趋势，构建品类指标分析模型，通过大数据及深度学习的NLP算法，自动化整合分析，快速发现、定位消费者的关注热点和需求变化。</p>
                    <img className='media-img' src={isMobile ? solutionchartMedia : solutionchart} alt="" />
                </div>
                <div>
                    <h3>b、竞品分析</h3>
                    <p>实时分析快消零售行业市场数据，感知市场动向，并跟竞品进行对比分析，挖掘差异化价值点，助力本品的新品研发，快速发现蓝海机会，抢占市场。</p>
                    <img className='media-img' src={isMobile ? experiencechartMedia : experiencechart} alt="" />
                </div>
            </div>
            <div className='solution-charts'>
                <div>
                    <h3>c、舆情监测</h3>
                    <p>国内外数十个电商、内容与社交平台的海量多维度、多渠道商品与消费者评论等数据为客户提供更全面的信息与洞见，让品牌高效进行稳定风控。</p>
                    <img className='media-img' src={isMobile ? publicSentimentMedia : publicSentiment} alt="" style={{ marginTop: '0.18rem' }} />
                </div>
                <div>
                    <h3>d、营销规划</h3>
                    <p>为快消零售行业搭建含数百个指标的分析体系，通过对消费者反馈大数据分析，应用于新品研发、营销策略调整等各个方面，整合渠道差异、优化数据增长。</p>
                    <img className='media-img' src={isMobile ? marketingMedia : marketing} alt="" />
                </div>
            </div>
        </div>
    </div>
}
export default CustomizedSolution;